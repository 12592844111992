<template>
  <div v-bind="attrs">
    <label v-if="props.label" class="form-label mb-0" :for="fullId">{{ props.label }}</label>
    <InlineLoaderTinyMCE :is-loading="isLoading" info="Editor wird geladen">
      <template v-if="props.inline">
        <div
          :id="fullId" :contenteditable="!disabled" v-bind="attrs"
          class="editable-content"
          :class="{ 'editable-border': !disabled }"
          v-html="props.modelValue"
        />
      </template>
      <template v-else>
        <textarea :id="fullId" :disabled="disabled" v-bind="attrs" :value="props.modelValue" />
      </template>
    </InlineLoaderTinyMCE>
  </div>
  <Modal ref="saveModal">
    <form :key="rollingSaveModalKey">
      <div class="row">
        <div class="col-6">
          <label :for="`client_${customId}`" class="form-label mb-0">Mandant</label>
          <Select
            @change="changeClientSaveModal"
            :id="`client_${customId}`"
            v-model="saveModalInput.clientId"
            :options="clients"
            return-value="id"
            text="name"
          />
        </div>
        <div class="col-6">
          <label :for="`baustein_name_${customId}`" class="form-label mb-0">Baustein Name</label>
          <Input
            :id="`baustein_name_${customId}`"
            v-model="saveModalInput.name"
            return-value="id"
            text="name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label :for="`categories_save_${customId}`" class="form-label mb-0">Kategorie</label>
          <Select
            :id="`categories_save_${customId}`"
            v-model="saveModalInput.categoryId"
            :options="saveCategoriesOptions"
            return-value="id"
            text="name"
          />
        </div>
      </div>

      <ModalFooter>
        <Button @click="saveModal.close();" html-type="secondary">
          <Icon icon="material-symbols-light:check" /> Schließen
        </Button>
        <Button @click="saveSnippet" html-type="primary" icon="material-symbols-light:check">
          Speichern
        </Button>
      </ModalFooter>
    </form>
  </Modal>
  <modal ref="loadModal">
    <form
      :key="rollingLoadModalKey"
    >
      <div class="row">
        <div class="col-6">
          <label for="categories" class="form-label mb-0">Baustein Kategorie</label>
          <Select
            @change="filierSnippets"
            id="categories"
            v-model="selectModalInput.categoryId"
            :options="selectableCategories"
            return-value="id"
            text="name"
          />
        </div>
        <div class="col-6">
          <label for="category_client_id" class="form-label mb-0">Mandant</label>
          <Select
            @change="filterCategories"
            id="category_client_id"
            v-model="selectModalInput.clientId"
            :options="clients"
            return-value="id"
            text="name"
          />
        </div>
      </div>
      <h5 class="mt-4 text-center">
        Verfügbare Bausteine
      </h5>
      <Card>
        <div class="list-group">
          <div v-for="snippet in selectableSnippets" :key="snippet.id" class="list-group-item list-group-item-action">
            <div @click="loadSnippet(snippet)" class="d-flex justify-content-between align-items-center snippet-row">
              <span class="snippet-name">{{ snippet.name }}</span>
              <span v-if="selectModalInput.categoryId === 0" class="snippet-category">{{ getCategoryName(snippet.categoryId) }}</span>
              <div @click.stop role="group" class="d-flex gap-1">
                <div @click.prevent="loadSnippet(snippet)" class="round-icon bg-primary-gradient">
                  <Icon icon="material-symbols-light:add" class="text-lg" />
                </div>
                <div @click.prevent="deleteSnippet(snippet)" class="round-icon">
                  <Icon icon="material-symbols-light:delete-forever-outline" class="text-lg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <ModalFooter />
    </form>
  </modal>
</template>

<script setup>
import InlineLoaderTinyMCE from "~/components/Layout/InlineLoaderTinyMCE.vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
  },
  init: {
    type: Object,
    default: () => ({}),
  },
  simpleMode: {
    type: Boolean,
    default: false,
  },
  modalInstance: {
    default: null,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  internalLoading: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minHeight: {
    type: Number,
    default: 500,
  },
  maxHeight: {
    type: Number,
    default: 1200,
  },
});
const emit = defineEmits([ "update:modelValue", "loaded", "promptAi" ]);
const colorMode = useColorMode();
const clients = ref([]);
const saveModal = ref(null);
const snippets = ref([]);
const selectableSnippets = ref([]);
const snippetsCategories = ref([]);
const saveCategories = ref([]);
const saveCategoriesOptions = ref([]);
const selectableCategories = ref([]);
const saveModalInput = ref({
  categoryId: 0,
  clientId: 0,
  name: "",
});
const selectModalInput = ref({
  categoryId: 0,
  clientId: 0,
  name: "",
});
const isLoading = ref(props.internalLoading);
const loadModal = ref(null);
const customId = ref(props.id);
const rollingSaveModalKey = ref(0);
const rollingLoadModalKey = ref(0);
const disabled = ref(props.disabled);
const stateClient = await getClient();
const { $repository, toast } = useNuxtApp();
const darkMode = computed(() => {
  return colorMode.preference === "dark";
});
let localModal = null;

const attrs = useAttrs();

const fullId = computed(() => {
  return `editor-${customId.value}`;
});

const toolbar = computed(() => {
  if (disabled.value) {
    return "";
  } else if (props.simpleMode) {
    return " bold italic | alignleft aligncenter alignright bullist | code";
  } else {
    return "undo redo restoredraft searchreplace|  bold italic | alignleft aligncenter alignright bullist | ";
  }
});

// Define default TinyMCE configuration

const defaultInit = {
  plugins: "link image code autosave fullscreen searchreplace quickbars insertdatetime lists advlist autoresize",
  autosave_interval: "5s",
  autosave_restore_when_empty: true,
  autosave_ask_before_unload: false,
  toolbar: toolbar.value,
  language: "de",
  quickbars_selection_toolbar: "bold italic | blocks | quicklink blockquote insertdatetime bullist",
  license_key: "gpl",
  promotion: false,
  min_height: props.minHeight,
  max_height: props.maxHeight,
  autoresize_bottom_margin: 20,
  language_url: "/assets/js/tinymce/langs/de.js",
  base_url: "/assets/js/tinymce",
  suffix: ".min",
  menubar: props.simpleMode || disabled.value ? "" : "file edit view insert format tools table help",
  readonly: disabled.value,
  inline: props.inline,
  content_style: "p { margin: 0; }",
};

const tinymceRequest = await $repository.tinymce.getSnippets();
if (tinymceRequest && tinymceRequest.success) {
  snippets.value = tinymceRequest.snippets;
} else {
  await toast.showError("Baustein", tinymceRequest.error);
}

const clientsRequest = await $repository.client.getClients();
if (clientsRequest && clientsRequest.success) {
  clients.value = clientsRequest.clients;
  saveModalInput.value.clientId = stateClient.value.id;
} else {
  await toast.showError("Baustein", clientsRequest.error);
}

function loadModalOpen() {
  rollingLoadModalKey.value++;
  loadModal.value.open();
}
function saveModalOpen() {
  rollingSaveModalKey.value++;
  saveModal.value.open();
}

function changeClientSaveModal() {
  saveCategoriesOptions.value = saveCategories.value.filter(category => category.clientId === saveModalInput.value.clientId);
}

const tinymceCategoryRequest = await $repository.tinymce.getSnippedCategories();
if (tinymceCategoryRequest && tinymceCategoryRequest.success) {
  saveCategories.value = [ ...tinymceCategoryRequest.categories ];
  saveCategoriesOptions.value = saveCategories.value.filter(category => category.clientId === stateClient.value.id);
  snippetsCategories.value = [ ...tinymceCategoryRequest.categories ];
  clients.value.forEach((client) => {
    snippetsCategories.value.unshift({
      id: 0,
      name: "Alle",
      clientId: client.id,
    });
  });
  selectModalInput.value.categoryId = 0;
  selectModalInput.value.clientId = stateClient.value.id;
  filterCategories();
  filierSnippets();
} else {
  await toast.showError("Baustein", tinymceCategoryRequest.error);
}

function getCategoryName(categoryId) {
  const category = snippetsCategories.value.find(cat => cat.id === categoryId);
  return category ? category.name : "Unbekannte Kategorie"; // Return category name or a default message
}

function resetContent() {
  if (editorInstance) {
    editorInstance.setContent(""); // Setzt den Inhalt des Editors zurück
  }
}

function filierSnippets() {
  const categoryId = selectModalInput.value.categoryId;
  const clientId = selectModalInput.value.clientId;
  selectableSnippets.value = snippets.value.filter((snippet) => {
    if (categoryId === 0) {
      return snippet.clientId === clientId;
    } else {
      return snippet.categoryId === categoryId;
    }
  });
}

function filterCategories() {
  const clientId = selectModalInput.value.clientId;
  selectableCategories.value = snippetsCategories.value.filter((category) => {
    return category.clientId === clientId;
  });
  selectModalInput.value.categoryId = 0;
  filierSnippets();
}

async function saveSnippet() {
  const name = saveModalInput.value?.name;
  const clientId = saveModalInput.value.clientId;
  const categoryId = saveModalInput.value?.categoryId;
  const existingSnippet = snippets.value.find((snippet) => {
    return snippet.name.toLowerCase().trim() === name.toLowerCase().trim() && snippet.clientId === clientId && snippet.categoryId === saveModalInput.value.categoryId;
  },
  );
  const update = !!existingSnippet;
  let result = [];
  if (!update) {
    result = [];
    result.isConfirmed = true;
  } else {
    result = await toast.showConfirmation("Baustein", `Baustein mit dem Namen ${name} existiert bereits! Überschreiben?`);
  }
  if (result.isConfirmed) {
    const htmlBody = tinymce.get(fullId.value).getContent();
    const body = {
      htmlBody,
      name,
      clientId,
      categoryId,
    };
    if (existingSnippet) {
      body.id = existingSnippet.id;
    }
    const saveSnippedRequest = await $repository.tinymce.saveSnippet(body);
    if (saveSnippedRequest && saveSnippedRequest.success) {
      saveModal.value.close();
      saveModalInput.value = {
        categoryId: 0,
        clientId,
        name: "",
      };
      const snippet = saveSnippedRequest.snippet;
      const existingSnippetIndex = snippets.value.findIndex(existingSnippet => existingSnippet.id === snippet.id);
      if (existingSnippetIndex !== -1) {
        snippets.value[existingSnippetIndex] = snippet;
      } else {
        snippets.value.push(snippet);
      }
      filterCategories();
      filierSnippets();
      toast.showSuccess("Baustein erfolgreich gespeichert!");
    } else if (saveSnippedRequest && saveSnippedRequest.error) {
      await toast.showError("Baustein", saveSnippedRequest.error);
    }
  }
}

function loadSnippet(snippet) {
  tinymce.get(fullId.value).insertContent(snippet.content);
  loadModal.value.open();
}

async function deleteSnippet(snippet) {
  const result = await toast.showConfirmation("Löschen", "Wollen Sie den Baustein wirklich löschen?");
  if (result.isConfirmed) {
    try {
      const deleteSnipetRequest = await $repository.tinymce.deleteSnippet(snippet.id);
      if (deleteSnipetRequest && deleteSnipetRequest.success) {
        const deletedSnippetId = deleteSnipetRequest.snippet.id;
        const indexSelect = selectableSnippets.value.findIndex(snippet => snippet.id === deletedSnippetId);
        const indexMain = snippets.value.findIndex(snippet => snippet.id === deletedSnippetId);
        if (indexSelect !== -1) {
          selectableSnippets.value.splice(indexSelect, 1);
        }
        if (indexMain !== -1) {
          snippets.value.splice(indexMain, 1);
        }

        toast.showSuccess("Baustein gelöscht!");
      }
    } catch (e) {
      await toast.showError("Whoops!", e);
    }
  }
}
const mergedInit = {
  ...defaultInit,
  ...props.init,
  toolbar: `${defaultInit.toolbar} ${props.init.additionalButtons || ""}`.trim(),
};
function initializeEditor() {
  if (typeof tinymce === "undefined") {
    console.error("TinyMCE failed to load");
    return; // Exit the function if TinyMCE is not loaded
  }
  const styling = {
    ...mergedInit,
    skin: darkMode.value ? "oxide-dark" : "oxide",
    content_css: darkMode.value ? "dark" : "default",
  };
  tinymce.init({
    target: document.getElementById(fullId.value),
    ...styling,
    setup: (editor) => {
      const focusInHandler = function (e) {
        if (e.target.closest(".tox-dialog")) {
          e.stopImmediatePropagation();
        }
      };
      editor.ui.registry.addButton("saveSnippet", {
        icon: "save",
        tooltip: "Baustein speichern",
        onAction: () => {
          saveModalOpen();
        },
      });
      editor.ui.registry.addButton("promptAi", {
        icon: "ai",
        tooltip: "KI Antwort",
        onAction: () => {
          emit("promptAi");
        },
      });
      editor.ui.registry.addButton("loadSnippet", {
        icon: "upload",
        tooltip: "Baustein laden",
        onAction: () => {
          loadModalOpen();
        },
      });
      editor.on("change keyup", () => {
        emit("update:modelValue", editor.getContent());
      });
      editor.on("OpenWindow", () => {
        if (localModal) {
          document.addEventListener("focusin", focusInHandler, true);
        }
      });

      editor.on("CloseWindow", () => {
        if (localModal) {
          document.removeEventListener("focusin", focusInHandler, true);
        }
      });

      editor.on("init", () => {
        isLoading.value = false;
        document.getElementById(fullId.value).style.visibility = "visible";
        editor.setContent(props.modelValue || "");
        emit("loaded");
      });
    },
  });
}
// Watch for changes in modelValue and update the TinyMCE content
watch(() => props.modelValue, (newValue) => {
  const activeEditor = tinymce.get(fullId.value);
  if (activeEditor && newValue !== activeEditor.getContent()) {
    // Ensure editor is ready before updating the content
    activeEditor.setContent(newValue || "");
    if (newValue === "") {
      activeEditor.undoManager.clear();
      activeEditor.undoManager.reset();
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(`tinymce-autosave-${window.location.pathname}`)) {
          localStorage.removeItem(key);
        }
      });
    }
  }
});
watch(colorMode, async () => {
  const editor = tinymce.get(fullId.value);
  if (editor) {
    editor.remove(); // Destroy existing TinyMCE instance
  }
  await nextTick(); // Ensure DOM updates before reinitializing
  initializeEditor();
});

onMounted(() => {
  if (props.modalInstance) {
    localModal = props.modalInstance.getModalObject();
  }

  const existingScript = document.getElementById("unique");
  if (existingScript) {
    initializeEditor();
  } else {
    const script = document.createElement("script");
    script.id = "unique";
    script.src = "/assets/js/tinymce/tinymce.min.js"; // Path to TinyMCE in public folder
    script.onload = initializeEditor;
    document.head.appendChild(script);
  }
});

onBeforeUnmount(() => {
  onBeforeUnmount(() => {
    const editorInstance = tinymce.get(fullId.value);
    if (editorInstance) {
      tinymce.remove(editorInstance);
    }
  });
});
</script>

<style scoped>
.list-group-item {
  transition: background-color 0.2s;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.snippet-row {
  cursor: pointer;
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between title and category */
  align-items: center; /* Center items vertically */
}

.snippet-name {
  max-width: 10%;
  flex-grow: 1;
  white-space: nowrap;
}

.snippet-category {
  margin: 0 10px;
}

.btn-group {
  display: flex; /* Ensure buttons are in a row */
}

/* MUSS IN MAIN STYLE */
.dark-mode .list-group-item {
  background-color: #343a40; /* Dark background */
  color: white; /* Light text */
}

.dark-mode .list-group-item:hover {
  background-color: #495057; /* Darker background on hover */
}
.icon-button .icon {
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: 100%; /* Ensure it takes full width */
  height: 100%; /* Ensure it takes full height */
}

.editable-content {
  padding: 8px;
  min-height: 100px; /* Make it a bit more visible */
}

.editable-border {
  border: 1px solid #ccc; /* Light gray border to indicate it's editable */
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.editable-border:focus {
  border-color: #007bff; /* Change border color when focused (blue, for example) */
  outline: none; /* Remove default outline */
}
</style>
